import { VehicleIntegration, VehicleManufacturer } from '@hiven-energy/hiven-client';

import { Brand, VehicleManufacturerWithoutStartStop } from './types';

const brandsWithStartStop: Brand[] = [
  {
    name: 'BMW',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.BMW,
  },
  {
    name: 'Citroen',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.CITROEN,
  },
  {
    name: 'Cupra',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.CUPRA,
  },
  {
    name: 'DS',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.DS,
  },
  {
    name: 'Ford',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.FORD,
  },
  {
    name: 'Hyundai',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.HYUNDAI,
  },
  {
    name: 'Jaguar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.JAGUAR,
  },
  {
    name: 'Kia',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.KIA,
  },
  {
    name: 'Land Rover',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.LAND_ROVER,
  },
  {
    name: 'Mini',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.MINI,
  },
  {
    name: 'Opel',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.OPEL,
  },
  {
    name: 'Peugeot',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.PEUGEOT,
  },
  {
    name: 'Skoda',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.SKODA,
  },
  {
    name: 'Tesla',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.TESLA,
  },
  {
    name: 'Vauxhall',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.VAUXHALL,
  },
  {
    name: 'Volkswagen',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.VOLKSWAGEN,
  },
];

const brandsWithoutStartTop: Brand[] = [
  {
    name: 'Audi',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.AUDI,
  },
  {
    name: 'Alfa Romeo',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.ALFA_ROMEO,
  },
  {
    name: 'Fiat',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.FIAT,
  },
  {
    name: 'Mazda',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.MAZDA,
  },
  {
    name: 'Mercedes Benz',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.MERCEDES,
  },
  {
    name: 'Porsche',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.PORSCHE,
  },
  {
    name: 'Nissan',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.NISSAN,
  },
  {
    name: 'Volvo',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.VOLVO,
  },
  {
    name: 'Renault',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturerWithoutStartStop.RENAULT,
  },
];

export const brands: Brand[] = [...brandsWithStartStop, ...brandsWithoutStartTop].sort((a, b) =>
  a.name.localeCompare(b.name),
);
