import { InputField } from '@hiven-energy/hiven-ui';
import React from 'react';
import { Controller, FieldError, FieldValues } from 'react-hook-form';

import { useAnalytics } from 'src/services/analytics';

import FormField from '../FormField';

import { TextFieldProps } from './types';

export const TextField = <K extends FieldValues>({
  control,
  placeholder,
  name,
  showErrors = true,
  analyticsPlace,
  withErrorHeight,
  ...inputProps
}: TextFieldProps<K>): React.JSX.Element => {
  const { trackInputValue } = useAnalytics();
  return (
    <Controller
      control={control}
      render={({ field: { value, onChange, onBlur }, formState }) => {
        const error = showErrors && (formState.errors[name] as FieldError);

        const handleBlur = () => {
          trackInputValue(name, inputProps.password ? '<password>' : value, analyticsPlace);
          onBlur();
        };

        return (
          <FormField error={error} withErrorHeight={withErrorHeight}>
            <InputField
              value={value || ''}
              onChange={onChange}
              placeholder={placeholder}
              autoCapitalize="none"
              testID={name}
              error={!!error}
              onBlur={handleBlur}
              {...inputProps}
            />
          </FormField>
        );
      }}
      name={name}
    />
  );
};
