import { ArrowRightIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FlatList, ListRenderItem } from 'react-native';

import { useAppTheme } from 'src/app-theme';
import DeviceLogo from 'src/components/DeviceLogo/DeviceLogo';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';

import { brands } from './constants';
import * as styled from './styles';
import { Brand } from './types';

type Props = ScreenProps<RouteId.VehicleBrands>;

const VehicleBrands: FC<Props> = ({ navigation }) => {
  const appTheme = useAppTheme();
  const { trackButtonClick } = useAnalytics();

  const handlePress = (item: Brand) => {
    trackButtonClick(item.name, 'VehicleBrand.manufacturer');
    navigation.navigate(RouteId.SmartCarDocs, { manufacturer: item.manufacturer });
  };

  const renderItem: ListRenderItem<Brand> = ({ item, index }) => (
    <styled.Manufacturer
      $isLast={index === brands.length - 1}
      activeOpacity={0.5}
      onPress={() => handlePress(item)}
      testID={item.name}
      accessibilityLabel={item.name}
    >
      <styled.Content>
        <DeviceLogo manufacturer={item.manufacturer} />
        <styled.Name variant="h2">{item.name}</styled.Name>
      </styled.Content>
      <styled.Action backgroundColor={appTheme.main.color}>
        <ArrowRightIcon color={colors.white} />
      </styled.Action>
    </styled.Manufacturer>
  );

  const keyExtractor = (item: Brand) => item.name;

  return (
    <styled.Container>
      <FlatList data={brands} renderItem={renderItem} keyExtractor={keyExtractor} />
    </styled.Container>
  );
};

export default VehicleBrands;
