import { ButtonProps } from '@hiven-energy/hiven-ui';
import React, { FC, PropsWithChildren, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { MessageId } from 'src/i18n';
import { useAnalytics } from 'src/services/analytics';

import * as styled from './styles';

interface EditPanelProps {
  editButton?: Pick<ButtonProps, 'onPress' | 'rightIcon' | 'title'>;
  titleLabel: MessageId;
}

export const EditPanel: FC<PropsWithChildren<EditPanelProps>> = ({ titleLabel, children, ...props }) => {
  const { trackButtonClick } = useAnalytics();
  const handleWithAnalytics = useCallback(() => {
    if (props.editButton?.onPress) {
      trackButtonClick(titleLabel);
      if (props.editButton.onPress) {
        props.editButton.onPress();
      }
    }
  }, [props.editButton?.onPress]);

  return (
    <styled.Container>
      <styled.Header>
        <styled.Title variant="h3">
          <FormattedMessage id={titleLabel} />
        </styled.Title>
        {props.editButton && (
          <styled.EditButton
            title={props.editButton.title}
            rightIcon={props.editButton.rightIcon}
            type="tertiary"
            onPress={handleWithAnalytics}
          />
        )}
      </styled.Header>
      {children}
    </styled.Container>
  );
};
