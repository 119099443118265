import { ClockIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { LabelValueRow } from 'src/components/LabelValue/LabelValue';
import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';
import { parseReadyTime } from 'src/containers/ready-time/Overview/utils';
import { ReadyTimeByType } from 'src/containers/ready-time/types';
import { useWeekDays } from 'src/hooks/useWeekDays';
import { formatTime } from 'src/utils/time';

import * as styled from './styles';

interface Props {
  timeByType: ReadyTimeByType;
  onEditPress: VoidFunction;
}

const ReadyTime: FC<Props> = ({ timeByType, onEditPress }) => {
  const intl = useIntl();
  const weekDays = useWeekDays();

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'common.preferences.readyTime.overview.title' })}
      icon={ClockIcon}
      onEditPress={onEditPress}
      analyticsPlace="Preferences.readyTime"
    >
      {timeByType.type === 'all_day' ? (
        <>
          <styled.Time variant="h4">{formatTime(timeByType.time)}</styled.Time>
          <styled.ScheduleType>
            {intl.formatMessage({ id: 'common.preferences.readyTime.allWeek.label' })}
          </styled.ScheduleType>
        </>
      ) : (
        <styled.WeekDaysContainer>
          {parseReadyTime(weekDays, timeByType.time).map(([time, range]) => (
            <LabelValueRow label={formatTime(time)} value={range} key={time} />
          ))}
        </styled.WeekDaysContainer>
      )}
    </PreferenceOverview>
  );
};

export default React.memo(ReadyTime);
