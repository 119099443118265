import { IconProps } from '@hiven-energy/hiven-ui';
import React, { FC, PropsWithChildren } from 'react';
import { View } from 'react-native';

import { colors } from 'src/theme';

import * as styled from './styles';

type LabelValueProps = PropsWithChildren<{
  icon?: FC<IconProps>;
  label: string;
  value?: string;
}>;

export const LabelValue: FC<LabelValueProps> = ({ icon: Icon, value, label, children }) => {
  return (
    <styled.Container>
      <styled.Content>
        {Icon && <Icon size={24} color={colors.sunshineYellow} />}
        <styled.Label lineHeight="normal" fontWeight="medium" $withIcon={!!Icon}>
          {label}
        </styled.Label>
        {value && (
          <styled.Value variant="h4" lineHeight="normal">
            {value}
          </styled.Value>
        )}
      </styled.Content>
      {children && <styled.Container $withLeftMargin>{children}</styled.Container>}
    </styled.Container>
  );
};

export const LabelValueLine: FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <styled.Content>
      <styled.LabelInLine lineHeight="normal">{label}</styled.LabelInLine>
      {value && <styled.Value variant="h4">{value}</styled.Value>}
    </styled.Content>
  );
};

export const LabelValueRow: FC<{ label: string | undefined; value: string | undefined }> = ({ value, label }) => {
  return (
    <View>
      {label !== undefined && <styled.LabelInRow variant="h3">{label}</styled.LabelInRow>}
      {value !== undefined && <styled.ValueInRow>{value}</styled.ValueInRow>}
    </View>
  );
};
