import { Device, DeviceType, UserLocation, VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import { EllipsisIcon, IconButton } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useVehiclePreferences } from 'src/queries/sdk';
import { AttachedDeviceSelection } from 'src/screens/ChargingLocation/types';

export const VehicleMenu: FC<{
  device: Device;
  onDeviceMenuOpen: (arg: AttachedDeviceSelection) => void;
  location: UserLocation;
}> = ({ device, onDeviceMenuOpen, location }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const vehiclePreferencesQuery = useVehiclePreferences(device.id);

  const getPreferences = () => {
    const preferences = vehiclePreferencesQuery.data!;
    const chargingLocationIds = preferences.chargingLocationIds
      ? preferences.chargingLocationIds.filter(id => location.id !== id)
      : undefined;

    return {
      ...preferences,
      chargingLocationIds,
      chargingBehavior:
        chargingLocationIds?.length === 0 ? VehicleChargingBehavior.SMART_CHARGE_OFF : preferences.chargingBehavior,
      defaultSpotPriceAreaId:
        preferences.defaultSpotPriceAreaId !== location.spotPriceAreaId && chargingLocationIds?.length !== 0
          ? preferences.defaultSpotPriceAreaId
          : undefined,
    };
  };

  return (
    <IconButton
      icon={EllipsisIcon}
      type="plain"
      accessibilityLabel={intl.formatMessage({ id: 'common.menu' })}
      onPress={() => onDeviceMenuOpen({ preferences: getPreferences(), type: DeviceType.VEHICLE, device })}
      testID={a11y.formatLabel('common.menu')}
      disabled={!vehiclePreferencesQuery.isSuccess}
    />
  );
};
