import {
  AlfaRomeo,
  Audi,
  Bmw,
  Citroen,
  Cupra,
  Ds,
  Fiat,
  Ford,
  Hyundai,
  Jaguar,
  Kia,
  LandRover,
  Mazda,
  Mercedes,
  Mini,
  Nissan,
  Opel,
  Peugeot,
  Porsche,
  Renault,
  Skoda,
  Tesla,
  Toyota,
  Vauxhall,
  Volkswagen,
  Volvo,
  Wallbox,
  WallboxLong,
} from '@hiven-energy/hiven-brands';
import { ChargerManufacturer, VehicleManufacturer } from '@hiven-energy/hiven-client';
import { FC } from 'react';
import { SvgProps } from 'react-native-svg';

import { VehicleManufacturerWithoutStartStop } from '../../screens/brands/VehicleBrands/types';

export type Manufacturer = ChargerManufacturer | VehicleManufacturer | VehicleManufacturerWithoutStartStop;

export type SvgLogo = FC<SvgProps>;

export const manufacturerToLogoMap: Record<Manufacturer, SvgLogo | { default: SvgLogo; long: SvgLogo }> = {
  [VehicleManufacturer.BMW]: Bmw,
  [VehicleManufacturer.CITROEN]: Citroen,
  [VehicleManufacturer.CUPRA]: Cupra,
  [VehicleManufacturer.DS]: Ds,
  [VehicleManufacturer.FORD]: Ford,
  [VehicleManufacturer.HYUNDAI]: Hyundai,
  [VehicleManufacturer.JAGUAR]: Jaguar,
  [VehicleManufacturer.KIA]: Kia,
  [VehicleManufacturer.LAND_ROVER]: LandRover,
  [VehicleManufacturer.MINI]: Mini,
  [VehicleManufacturer.OPEL]: Opel,
  [VehicleManufacturer.PEUGEOT]: Peugeot,
  [VehicleManufacturer.SKODA]: Skoda,
  [VehicleManufacturer.TESLA]: Tesla,
  [VehicleManufacturer.TOYOTA]: Toyota,
  [VehicleManufacturer.VAUXHALL]: Vauxhall,
  [VehicleManufacturer.VOLKSWAGEN]: Volkswagen,
  [VehicleManufacturer.VOLVO]: Volvo,
  [ChargerManufacturer.WALLBOX]: { default: Wallbox, long: WallboxLong },
  [VehicleManufacturerWithoutStartStop.AUDI]: Audi,
  [VehicleManufacturerWithoutStartStop.PORSCHE]: Porsche,
  [VehicleManufacturerWithoutStartStop.FIAT]: Fiat,
  [VehicleManufacturerWithoutStartStop.NISSAN]: Nissan,
  [VehicleManufacturerWithoutStartStop.MERCEDES]: Mercedes,
  [VehicleManufacturerWithoutStartStop.RENAULT]: Renault,
  [VehicleManufacturerWithoutStartStop.MAZDA]: Mazda,
  [VehicleManufacturerWithoutStartStop.ALFA_ROMEO]: AlfaRomeo,
};

export const LONG_LOGO_WIDTH = 143;
export const LONG_LOGO_HEIGHT = 33;
