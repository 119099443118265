import { Container as _Container, Button, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  padding: ${spacings.xxs}px;
  border-width: 1px;
  border-radius: 12px;
  border-color: ${colors.pidgeonGray};
`;

export const Content = styled(_Container)`
  flex-direction: row;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  color: ${colors.deepNavy};
`;

export const EditButton = styled(Button)`
  height: 24px;
  margin-right: -${spacings.xxs}px;
`;

export const Header = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;
