import { UserLocation } from '@hiven-energy/hiven-client';
import { ChevronRightIcon, PinIcon, spacings, TouchableOpacityContainer } from '@hiven-energy/hiven-ui';
import { useIsFocused } from '@react-navigation/native';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, RefreshControl } from 'react-native';

import { useA11y } from 'src/a11y';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useDevices, useUserLocations } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { colors } from 'src/theme';
import { groupDevicesByLocation } from 'src/utils/device';

import NoLocations from './NoLocations/NoLocations';
import * as styled from './styles';

type Props = ScreenProps<RouteId.MyChargingLocations>;

export const MyChargingLocations: FC<Props> = ({ navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  useIsFocused();

  useAnalyticsTimeEvent(MixpanelEvents.USER_DATA_SAVED);
  const { trackRefresh, trackButtonClick } = useAnalytics();

  const userLocationsQuery = useUserLocations();
  const userLocations = userLocationsQuery.data || [];

  const attachedDevicesByLocation = useDevices({
    select: devices => groupDevicesByLocation(userLocations, devices),
  });

  const navigateToLocationItem = useCallback(
    (item: UserLocation) => {
      navigation.navigate(RouteId.ChargingLocation, { locationId: item.id });
    },
    [navigation],
  );

  const handleAddNewLocationPress = () => {
    trackButtonClick('MyChargingLocations.addNewLocation');
    navigation.navigate(RouteId.SetChargingLocation, {});
  };

  const handleRefresh = () => {
    trackRefresh();
    userLocationsQuery.refetch();
  };

  return (
    <styled.Container safeAreaEdges={['left', 'right']}>
      {!userLocations.length ? (
        <NoLocations onAddNewLocationPress={handleAddNewLocationPress} />
      ) : (
        <>
          <styled.Content fontWeight="medium">
            <FormattedMessage id="MyChargingLocations.content" />
          </styled.Content>
          <styled.ListContainer>
            <FlatList
              data={userLocations}
              renderItem={({ item, index }) => (
                <LocationItem
                  index={index}
                  item={item}
                  deviceCount={attachedDevicesByLocation.data?.[item.id].length ?? 0}
                  navigateToLocationItem={navigateToLocationItem}
                />
              )}
              keyExtractor={keyExtractor}
              contentContainerStyle={{ paddingLeft: spacings.s, paddingRight: spacings.s }}
              refreshControl={
                <RefreshControl
                  tintColor={colors.deepNavy}
                  refreshing={userLocationsQuery.isLoading}
                  onRefresh={handleRefresh}
                />
              }
              extraData={attachedDevicesByLocation}
            />
          </styled.ListContainer>
          <styled.Button
            title={intl.formatMessage({ id: 'MyChargingLocations.addNewLocation' })}
            onPress={handleAddNewLocationPress}
            testID={a11y.formatLabel('MyChargingLocations.addNewLocation')}
          />
        </>
      )}
    </styled.Container>
  );
};

const LocationItem: FC<{
  item: UserLocation;
  index: number;
  deviceCount: number;
  navigateToLocationItem: (item: UserLocation) => void;
}> = ({ item, index, deviceCount, navigateToLocationItem }) => {
  return (
    <styled.LocationItem $firstItem={index === 0}>
      <TouchableOpacityContainer onPress={() => navigateToLocationItem(item)}>
        <styled.LocationTitleContent>
          <PinIcon size={18} />
          <styled.LocationTitle variant="h2">{item.name}</styled.LocationTitle>
          <ChevronRightIcon color={colors.hintGrey} />
        </styled.LocationTitleContent>
        <styled.AddressLine>{item.streetAddress}</styled.AddressLine>
        <styled.CityContainer>
          <styled.CityText>{item.postalCode}</styled.CityText>
          <styled.CityText>{item.city}</styled.CityText>
        </styled.CityContainer>
        <styled.AttachedDevicesText>
          <FormattedMessage id="MyChargingLocations.attachedDevices" values={{ count: deviceCount }} />
        </styled.AttachedDevicesText>
      </TouchableOpacityContainer>
    </styled.LocationItem>
  );
};

const keyExtractor = (item: UserLocation) => `${item.id}-${item.name}`;
