import { Device, UserLocation } from '@hiven-energy/hiven-client';
import { Button, Checkbox, spacings } from '@hiven-energy/hiven-ui';
import sortBy from 'lodash/sortBy';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlatList, FlatListProps, View } from 'react-native';

import * as styled from 'src/containers/YourChargingLocations/styles';
import { getUserLocation } from 'src/containers/YourChargingLocations/utils';
import { useDevices, useUserLocations } from 'src/queries/sdk';
import { groupDevicesByLocation } from 'src/utils/device';

type Props =
  | {
      onConfirm: (locations: UserLocation[]) => void;
      onAddNewLocation: VoidFunction;
      saving?: boolean;
      selectMultiple: true;
      selectedLocationsIds: string[];
    }
  | {
      onConfirm: (location: UserLocation | undefined) => void;
      onAddNewLocation: VoidFunction;
      saving?: boolean;
      selectedLocationId: string | undefined;
    };

const YourChargingLocations: FC<Props> = props => {
  const intl = useIntl();

  const userLocationsQuery = useUserLocations({
    select: locations => sortBy(locations, 'name'),
  });
  const attachedDevicesByLocation = useDevices({
    select: devices => groupDevicesByLocation(userLocationsQuery.data ?? [], devices),
  });

  useEffect(() => {
    if (userLocationsQuery.isSuccess && !userLocationsQuery.data.length) {
      props.onAddNewLocation();
    }
  }, [userLocationsQuery]);

  const listContent = useCallback(
    (selected: string[] | string | undefined, setSelected: (id: string) => void, onConfirm: VoidFunction) => {
      return (
        <ListContentComponent
          onAddNewLocation={props.onAddNewLocation}
          locations={userLocationsQuery.data}
          devicesByLocation={attachedDevicesByLocation.data}
          saving={props.saving}
          onConfirm={onConfirm}
          selected={selected}
          setSelected={setSelected}
        />
      );
    },
    [props.onAddNewLocation, userLocationsQuery, attachedDevicesByLocation],
  );

  return (
    <styled.Container>
      <styled.Header>{intl.formatMessage({ id: 'yourChargingLocations.title' })}</styled.Header>
      {userLocationsQuery.data &&
        ('selectMultiple' in props ? (
          <SelectMultipleContainer
            onContinue={props.onConfirm}
            getUserLocation={getUserLocation(userLocationsQuery.data)}
            selectedLocationsIds={props.selectedLocationsIds}
          >
            {listContent}
          </SelectMultipleContainer>
        ) : (
          <SelectContainer
            onContinue={props.onConfirm}
            getUserLocation={getUserLocation(userLocationsQuery.data)}
            selectedLocationId={props.selectedLocationId}
          >
            {listContent}
          </SelectContainer>
        ))}
    </styled.Container>
  );
};

export default YourChargingLocations;

const ListContentComponent: FC<{
  selected: string[] | string | undefined;
  saving?: boolean;
  setSelected: (id: string) => void;
  locations: UserLocation[] | undefined;
  devicesByLocation: Record<string, Device[]> | undefined;
  onAddNewLocation: VoidFunction;
  onConfirm: VoidFunction;
}> = ({ selected, saving, locations, setSelected, devicesByLocation, onAddNewLocation, onConfirm }) => {
  const intl = useIntl();

  const renderItem: FlatListProps<UserLocation>['renderItem'] = ({ item }) => {
    const checked = Array.isArray(selected) ? selected.includes(item.id) : item.id === selected;
    return (
      <Location
        key={item.id}
        location={item}
        attachedDevices={devicesByLocation?.[item.id]?.length ?? 0}
        checked={checked}
        onCheck={setSelected}
      />
    );
  };

  return (
    <>
      <FlatList
        contentContainerStyle={{ paddingHorizontal: spacings.s, gap: spacings.xxs }}
        data={locations}
        keyExtractor={({ id }) => id}
        renderItem={renderItem}
      />
      <styled.Buttons>
        <Button
          type="secondary"
          title={intl.formatMessage({ id: 'yourChargingLocations.addNewLocation.title' })}
          onPress={onAddNewLocation}
        />
        <Button
          title={intl.formatMessage({ id: 'common.confirm' })}
          loading={saving}
          disabled={saving}
          onPress={onConfirm}
        />
      </styled.Buttons>
    </>
  );
};

const SelectContainer: FC<{
  onContinue: (location: UserLocation | undefined) => void;
  children: (selected: string | undefined, setSelected: (id: string) => void, onSave: VoidFunction) => ReactNode;
  getUserLocation: (id: string) => UserLocation;
  selectedLocationId: string | undefined;
}> = ({ onContinue, getUserLocation, selectedLocationId, children }) => {
  const [selected, setSelected] = useState<string | undefined>(selectedLocationId);
  useEffect(() => {
    setSelected(selectedLocationId);
  }, [selectedLocationId]);
  return (
    <>
      {children(
        selected,
        newSelected => {
          setSelected(previous => (previous === newSelected ? undefined : newSelected));
        },
        () => {
          onContinue(selected ? getUserLocation(selected) : undefined);
        },
      )}
    </>
  );
};

const SelectMultipleContainer: FC<{
  onContinue: (locations: UserLocation[]) => void;
  children: (selected: string[], setSelected: (id: string) => void, onSave: VoidFunction) => ReactNode;
  getUserLocation: (id: string) => UserLocation;
  selectedLocationsIds: string[];
}> = ({ onContinue, getUserLocation, selectedLocationsIds, children }) => {
  const [selected, setSelected] = useState<string[]>(selectedLocationsIds);
  useEffect(() => {
    setSelected(selectedLocationsIds);
  }, [selectedLocationsIds]);
  return (
    <>
      {children(
        selected,
        id =>
          setSelected(prevState => {
            if (prevState.includes(id)) return prevState.filter(prevId => prevId !== id);
            return [...prevState, id];
          }),
        () => onContinue(selected.map(getUserLocation)),
      )}
    </>
  );
};

const Location: FC<{
  attachedDevices: number;
  location: UserLocation;
  checked: boolean;
  onCheck: (id: string) => void;
}> = ({ attachedDevices, onCheck, location, checked }) => {
  return (
    <styled.LocationItem>
      <styled.TouchableContainer onPress={() => onCheck(location.id)}>
        <styled.LocationHeader>
          <styled.LocationName>{location.name}</styled.LocationName>
          <View pointerEvents="none">
            <Checkbox checked={checked} />
          </View>
        </styled.LocationHeader>
        <styled.Address>
          <styled.AddressLine>{`${location.streetAddress}, ${location.postalCode}`}</styled.AddressLine>
          <styled.AddressLine>{location.city}</styled.AddressLine>
        </styled.Address>
        <styled.AttachedDeviceLine>
          <FormattedMessage id="youChargingLocations.attachedDevices" values={{ attachedDevices }} />
        </styled.AttachedDeviceLine>
      </styled.TouchableContainer>
    </styled.LocationItem>
  );
};
