import { ChartIcon, CogIcon, HomeIcon, IconProps } from '@hiven-energy/hiven-ui';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { MessageId } from 'src/i18n';
import { RouteId } from 'src/nav/types';
import { useDevice } from 'src/queries/sdk';
import { colors } from 'src/theme';
import { isVehicle } from 'src/utils/device';

import * as styled from './styles';

interface Tab {
  icon: FC<IconProps>;
  translationMessageId: MessageId;
  color: string;
  activeColor: string;
  routes: string[];
}

export type TabBarRoute =
  | RouteId.ChargerDashboard
  | RouteId.Settings
  | RouteId.ChargerAnalytics
  | RouteId.VehicleAnalytics
  | RouteId.VehicleDashboard;

const tabs: Tab[] = [
  {
    icon: HomeIcon,
    translationMessageId: 'navigation.screen.Dashboard',
    color: colors.hintGrey,
    activeColor: colors.deepNavy,
    routes: [RouteId.ChargerDashboard, RouteId.VehicleDashboard],
  },
  {
    icon: ChartIcon,
    translationMessageId: 'navigation.screen.Analytics',
    color: colors.hintGrey,
    activeColor: colors.deepNavy,
    routes: [RouteId.ChargerAnalytics, RouteId.VehicleAnalytics],
  },
  {
    icon: CogIcon,
    translationMessageId: 'navigation.screen.Settings',
    color: colors.hintGrey,
    activeColor: colors.deepNavy,
    routes: [RouteId.Settings],
  },
];

type MapRoutes = RouteId.ChargerDashboard | RouteId.VehicleDashboard;

const DashboardMapping: Record<MapRoutes, TabBarRoute[]> = {
  [RouteId.ChargerDashboard]: [RouteId.ChargerDashboard, RouteId.ChargerAnalytics, RouteId.Settings],
  [RouteId.VehicleDashboard]: [RouteId.VehicleDashboard, RouteId.VehicleAnalytics, RouteId.Settings],
};

const TabBar: FC<{ deviceId: string }> = ({ deviceId }) => {
  const navigation = useNavigation();
  const { data: device } = useDevice(deviceId);
  const currentRoute = useRoute();
  const dashboardRouteId = useMemo<MapRoutes | RouteId.Initial>(() => {
    if (device) {
      return isVehicle(device) ? RouteId.VehicleDashboard : RouteId.ChargerDashboard;
    }
    return RouteId.Initial;
  }, [device]);

  return (
    <styled.Container>
      <styled.TabBar>
        {tabs.map((tab, tabIndex) => {
          const isActive = tab.routes.includes(currentRoute.name);
          const onPress = () => {
            if (dashboardRouteId !== RouteId.Initial) {
              navigation.navigate(DashboardMapping[dashboardRouteId][tabIndex], { deviceId });
            } else {
              navigation.navigate(RouteId.Initial);
            }
          };
          return (
            <styled.Tab key={tabIndex} onPress={onPress}>
              {<tab.icon color={isActive ? tab.activeColor : tab.color} />}
              <styled.TabName fontSize={14} fontWeight="medium" $color={isActive ? tab.activeColor : tab.color}>
                <FormattedMessage id={tab.translationMessageId} />
              </styled.TabName>
            </styled.Tab>
          );
        })}
      </styled.TabBar>
    </styled.Container>
  );
};

export default React.memo(TabBar);
