import { QueryStatus, UseQueryResult } from '@tanstack/react-query';

export enum ErrorReason {
  UNKNOWN = -1,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export const getQueryStatus = (query: UseQueryResult) => getQueriesStatus(query);

export const getQueriesStatus = (...queries: UseQueryResult[]): QueryStatus => {
  let queriesStatus: QueryStatus = 'success';
  if (queries.some(isQueryLoading)) {
    queriesStatus = 'loading';
  } else if (queries.some(isQueryError)) {
    queriesStatus = 'error';
  }
  return queriesStatus;
};

const isQueryLoading = (query: UseQueryResult) => {
  // Query has 'loading' status when disabled
  const enabled = query.fetchStatus !== 'idle';
  const isLoading = enabled && query.isLoading;
  return isLoading || query.isFetching;
};

const isQueryError = (query: UseQueryResult) => query.isError;

export const getErrorReason = (response: Response) =>
  Object.values(ErrorReason).includes(response.status) ? (response.status as ErrorReason) : ErrorReason.UNKNOWN;
