import { DeviceType, VehicleChargingBehavior, VehicleChargingStatus } from '@hiven-energy/hiven-client';
import { spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Platform, RefreshControl, ScrollView } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Loader from 'src/components/Loader/Loader';
import ScheduledRefresh from 'src/components/ScheduledRefresh/ScheduledRefresh';
import ChargingActions from 'src/containers/ChargingActions/ChargingActions';
import ConnectionPending from 'src/containers/dashboard/ConnectionPending/ConnectionPending';
import Offline from 'src/containers/dashboard/Offline/Offline';
import TabBar from 'src/containers/TabBar/TabBar';
import TimeSchedule from 'src/containers/TimeSchedule/TimeSchedule';
import { useScheduledCall } from 'src/hooks/useScheduledCall';
import { useUserRefresh } from 'src/hooks/useUserRefresh';
import { RouteId, ScreenProps } from 'src/nav/types';
import {
  useChargingSchedule,
  useStartImmediateCharging,
  useStopImmediateCharging,
  useUserLocations,
  useVehicle,
  useVehiclePreferences,
  useVehicleStatus,
} from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useTrackSuccessUpdate } from 'src/services/analytics/useTrackSuccessUpdate';
import { colors } from 'src/theme';
import { getDate } from 'src/utils/date';
import { DEVICE_CONNECTING_THRESHOLD_SECONDS } from 'src/utils/device';
import { getNextUpdateDate } from 'src/utils/next-update';
import { ErrorReason, getErrorReason } from 'src/utils/queries';

import { arePreferencesSet, parsePreferences } from '../utils';

import { CHARGING_SCHEDULE_REFRESH_MILLISECONDS, VEHICLE_STATUS_REFRESH_SECONDS } from './constants';
import MissingPreferences from './MissingPreferences/MissingPreferences';
import Preferences from './Preferences/Preferences';
import Status from './Status/Status';
import * as styled from './styles';

export interface RoutedProps {
  deviceId: string;
  showDeviceList?: string;
}

type Props = ScreenProps<RouteId.VehicleDashboard>;

const Dashboard: FC<Props> = ({ navigation, route: { params } }) => {
  const intl = useIntl();
  const toast = useToast();
  const { trackRefresh } = useAnalytics();

  const { deviceId } = params;
  const showDeviceList = params.showDeviceList === 'true';
  const vehicleQuery = useVehicle(deviceId);
  const { data: vehicle } = vehicleQuery;

  const statusQuery = useVehicleStatus(deviceId, {
    structuralSharing: false,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason === ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'vehicleStatus.fetch.missingTelemetry' });
        toast.show(message, { type: 'warning' });
      } else {
        const message = intl.formatMessage({ id: 'vehicleStatus.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });
  const { data: status } = statusQuery;

  const nextStatusUpdate = useMemo(() => {
    const lastUpdate = getDate(status?.timestamp);
    return getNextUpdateDate(lastUpdate, VEHICLE_STATUS_REFRESH_SECONDS);
  }, [status]);

  useScheduledCall(statusQuery.refetch, { targetDate: nextStatusUpdate });

  const preferencesQuery = useVehiclePreferences(deviceId, {
    enabled: statusQuery.isSuccess,
    onError: error => {
      const errorReason = getErrorReason(error);
      if (errorReason !== ErrorReason.NOT_FOUND) {
        const message = intl.formatMessage({ id: 'VehiclePreferences.fetch.error' });
        toast.show(message, { type: 'danger' });
      }
    },
  });

  const isChargingScheduleQueryEnabled =
    preferencesQuery.isSuccess && preferencesQuery.data.chargingBehavior !== VehicleChargingBehavior.SMART_CHARGE_OFF;

  const chargingScheduleQuery = useChargingSchedule(deviceId, {
    enabled: isChargingScheduleQueryEnabled,
    refetchInterval: CHARGING_SCHEDULE_REFRESH_MILLISECONDS,
  });

  const userLocationsQuery = useUserLocations();

  const startImmediateChargingMutation = useStartImmediateCharging();
  const stopImmediateChargingMutation = useStopImmediateCharging();

  const connectionWaitDateLimit = useMemo(() => {
    const currentDate = getDate();
    const waitDate = vehicle && getDate(vehicle.createdAt).add(DEVICE_CONNECTING_THRESHOLD_SECONDS, 'seconds');
    return !status && waitDate && waitDate > currentDate ? waitDate : undefined;
  }, [vehicle, status]);

  useScheduledCall(statusQuery.refetch, { targetDate: connectionWaitDateLimit!, enabled: !!connectionWaitDateLimit });

  const preferencesSet = useMemo(
    () => preferencesQuery.data && arePreferencesSet(preferencesQuery.data),
    [preferencesQuery.data],
  );

  const preferences = useMemo(() => {
    const locationIds = preferencesQuery.data?.chargingLocationIds;
    const locations = locationIds?.length ? userLocationsQuery.data?.filter(({ id }) => locationIds.includes(id)) : [];
    return parsePreferences(preferencesQuery.data, vehicle, locations);
  }, [vehicle, preferencesQuery.data, userLocationsQuery.data]);

  useTrackSuccessUpdate(chargingScheduleQuery, MixpanelEvents.USER_WAS_WAITING_FOR_SCHEDULE);

  const navigateToPreferences = () => {
    navigation.navigate(RouteId.VehiclePreferences, { deviceId });
  };

  const handleStartImmediateCharging = () => {
    startImmediateChargingMutation.mutate(deviceId);
  };

  const handleStopImmediateCharging = () => {
    stopImmediateChargingMutation.mutate(deviceId);
  };

  const handleToggleDeviceList = () => {
    navigation.setParams({ showDeviceList: `${!showDeviceList}` });
  };

  const refresh = useCallback(async () => {
    trackRefresh();
    await Promise.all([statusQuery.refetch(), isChargingScheduleQueryEnabled && chargingScheduleQuery.refetch()]);
  }, [isChargingScheduleQueryEnabled, statusQuery.refetch, chargingScheduleQuery.refetch, trackRefresh]);

  const [refreshing, handleRefresh] = useUserRefresh(refresh);

  const isContentReady = !((statusQuery.isSuccess && preferencesQuery.isLoading) || userLocationsQuery.isLoading);

  if (vehicleQuery.isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <styled.Container>
      <Status
        name={vehicle?.attributes.name}
        lastHeartbeat={status?.timestamp}
        chargeLevels={preferences.chargeLevels}
        vehicleStatus={status}
        connectionPending={!!connectionWaitDateLimit}
        preferencesSet={preferencesSet}
        deviceId={vehicle?.id}
        showDeviceList={showDeviceList}
        onToggleDeviceList={handleToggleDeviceList}
      />
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: spacings.xs,
          justifyContent: !isContentReady ? 'center' : 'flex-start',
        }}
        refreshControl={
          <RefreshControl tintColor={colors.deepNavy} refreshing={refreshing} onRefresh={handleRefresh} />
        }
      >
        {isContentReady && (
          <>
            {connectionWaitDateLimit ? (
              <ConnectionPending deviceType={DeviceType.VEHICLE} dateLimit={connectionWaitDateLimit} />
            ) : !status ? (
              <Offline
                deviceType={DeviceType.VEHICLE}
                isRefetching={statusQuery.isFetching}
                onTryAgainPress={statusQuery.refetch}
              />
            ) : !preferencesSet ? (
              <MissingPreferences
                deviceName={vehicle?.attributes.name}
                preferences={preferences}
                onSetPreferencesPress={navigateToPreferences}
              />
            ) : (
              <styled.Dashboard>
                {preferences.chargingBehavior !== VehicleChargingBehavior.SMART_CHARGE_OFF && status?.chargingStatus && (
                  <styled.Section>
                    <ChargingActions
                      isCharging={status.chargingStatus === VehicleChargingStatus.CHARGING}
                      handleStartConfirm={handleStartImmediateCharging}
                      handleEndConfirm={handleStopImmediateCharging}
                    />
                  </styled.Section>
                )}
                <styled.Section>
                  <TimeSchedule
                    chargingSchedule={chargingScheduleQuery.isSuccess ? chargingScheduleQuery.data : undefined}
                    disabled={preferences.chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_OFF}
                    complete={status?.chargingStatus === VehicleChargingStatus.COMPLETE}
                  />
                </styled.Section>
                <styled.Section>
                  <Preferences preferences={preferences} onEditPress={navigateToPreferences} />
                </styled.Section>
                <styled.Section>
                  <ScheduledRefresh
                    refreshing={statusQuery.isFetching}
                    nextRefreshDate={nextStatusUpdate}
                    disabled={Platform.OS !== 'web'}
                    onRefresh={refresh}
                  />
                </styled.Section>
              </styled.Dashboard>
            )}
          </>
        )}
        {!isContentReady && <Loader color={colors.deepNavy} />}
      </ScrollView>
      <TabBar deviceId={deviceId} />
    </styled.Container>
  );
};

export default Dashboard;
