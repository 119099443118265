import { Charger as ChargerType } from '@hiven-energy/hiven-client';
import { CarIcon, CheckIcon, ChevronRightIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useDevices } from 'src/queries/sdk';
import { colors } from 'src/theme';

import { Charger } from '../Charger';

import * as styled from './styles';

interface Props {
  id: string;
  name: string;
  onVehiclePress: VoidFunction;
  onChargerPress?: VoidFunction;
  selectedDeviceId: string;
  chargerIds: string[];
}

const PairedVehicle: FC<Props> = ({ id, name, onVehiclePress, onChargerPress, selectedDeviceId, chargerIds }) => {
  const a11y = useA11y();

  const devices = useDevices();

  const isSelected = id === selectedDeviceId;
  return (
    <styled.Container>
      <styled.Touchable
        accessibilityLabel={a11y.formatLabel('selectDevice.label', { name })}
        testID={a11y.formatLabel('selectDevice.label', { name })}
        disabled={isSelected}
        onPress={onVehiclePress}
        activeOpacity={0.5}
      >
        <CarIcon color={colors.white} />
        <styled.Details>
          <styled.Name
            variant="h2"
            accessibilityLabel={a11y.formatLabel('selectDevice.name')}
            testID={a11y.formatLabel('selectDevice.name')}
          >
            {name}
          </styled.Name>
          <styled.Hint variant="hint">
            <FormattedMessage id="deviceList.vehicle.hasAssociation" />
          </styled.Hint>
        </styled.Details>
        {isSelected ? (
          <styled.CheckIconWrapper>
            <CheckIcon size={20} color={colors.deepNavy} />
          </styled.CheckIconWrapper>
        ) : (
          <ChevronRightIcon color={colors.aqua} />
        )}
      </styled.Touchable>
      {chargerIds.length > 0 && (
        <styled.Chargers>
          {chargerIds.map(id => {
            const charger = devices.data?.find(device => device.id === id) as ChargerType | undefined;
            return (
              charger && (
                <Charger key={id} device={charger} selectedDeviceId={selectedDeviceId} onPress={onChargerPress} />
              )
            );
          })}
        </styled.Chargers>
      )}
    </styled.Container>
  );
};

export default React.memo(PairedVehicle);
