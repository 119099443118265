import { AccordionIcon, BinIcon, ChevronRightIcon, PinIcon, spacings } from '@hiven-energy/hiven-ui';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import PreferenceOverview from 'src/components/PreferenceOverview/PreferenceOverview';
import LocationNotSetView from 'src/containers/charging-location/Overview/LocationNotSetView';
import { Preferences } from 'src/screens/vehicle/types';
import { AnalyticPlace } from 'src/services/analytics/types';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  onAddLocationPress: VoidFunction;
  onRemoveLocation: (index: number) => void;
  analyticsPlace: AnalyticPlace;
  chargingLocations: Preferences['chargingLocations'];
  isLoading: boolean;
}

const ChargingLocations: FC<Props> = ({
  onAddLocationPress,
  analyticsPlace,
  chargingLocations,
  isLoading,
  onRemoveLocation,
}) => {
  const intl = useIntl();
  const [deleteLocationIndex, setDeleteLocationIndex] = useState<number | null>(null);

  return (
    <PreferenceOverview
      title={intl.formatMessage({ id: 'VehiclePreferences.chargingLocations.overview.title' })}
      icon={PinIcon}
      contentPadding={chargingLocations.length ? spacings.xxs : 0}
      analyticsPlace={analyticsPlace}
    >
      {isLoading && <styled.DeviceLoader color={colors.deepNavy} />}
      {!chargingLocations.length && !isLoading && <LocationNotSetView onAddLocationPress={onAddLocationPress} />}
      {!!chargingLocations.length && (
        <>
          <styled.LocationList>
            {chargingLocations.map((item, index) => (
              <React.Fragment key={index}>
                {item.id ? (
                  <ExistingLocation chargingLocation={item} onAddLocationPress={onAddLocationPress} />
                ) : (
                  <NewLocation onRemoveLocation={() => setDeleteLocationIndex(index)} chargingLocation={item} />
                )}
              </React.Fragment>
            ))}
          </styled.LocationList>
          <styled.AddLocationOption onPress={onAddLocationPress} $withTopMargin={!!chargingLocations.length}>
            <AccordionIcon />
            <styled.AddLocationText fontWeight="semiBold">
              {intl.formatMessage({ id: 'VehiclePreferences.chargingLocations.addChargingLocation' })}
            </styled.AddLocationText>
          </styled.AddLocationOption>
          {deleteLocationIndex !== null && (
            <ConfirmationModal
              title={intl.formatMessage({
                id: 'VehiclePreferences.chargingLocations.deleteLocation.dialog.title',
              })}
              content={intl.formatMessage({
                id: 'VehiclePreferences.chargingLocations.deleteLocation.dialog.content',
              })}
              onConfirm={() => {
                onRemoveLocation(deleteLocationIndex);
                setDeleteLocationIndex(null);
              }}
              onCancel={() => setDeleteLocationIndex(null)}
            />
          )}
        </>
      )}
    </PreferenceOverview>
  );
};

const ExistingLocation: FC<{
  onAddLocationPress: VoidFunction;
  chargingLocation: Preferences['chargingLocations'][number];
}> = ({ onAddLocationPress, chargingLocation }) => {
  return (
    <styled.ItemContainer onPress={onAddLocationPress}>
      <styled.LocationContainer>
        <styled.LocationHeaderText variant="h4">{chargingLocation.payload.name}</styled.LocationHeaderText>
        <styled.LocationAddressText>{chargingLocation.payload.streetAddress}</styled.LocationAddressText>
        <styled.LocationAddressText>{`${chargingLocation.payload.postalCode} ${chargingLocation.payload.city}`}</styled.LocationAddressText>
      </styled.LocationContainer>
      <styled.ChevronIconContainer>
        <ChevronRightIcon />
      </styled.ChevronIconContainer>
    </styled.ItemContainer>
  );
};

const NewLocation: FC<{
  onRemoveLocation: VoidFunction;
  chargingLocation: Preferences['chargingLocations'][number];
}> = ({ onRemoveLocation, chargingLocation }) => {
  return (
    <styled.NewItemContainer>
      <styled.LocationContainer>
        <styled.LocationHeaderText variant="h4">{chargingLocation.payload.name}</styled.LocationHeaderText>
        <styled.LocationAddressText>{chargingLocation.payload.streetAddress}</styled.LocationAddressText>
        <styled.LocationAddressText>{`${chargingLocation.payload.postalCode} ${chargingLocation.payload.city}`}</styled.LocationAddressText>
      </styled.LocationContainer>
      <styled.BinIconContainer onPress={onRemoveLocation}>
        <BinIcon />
      </styled.BinIconContainer>
    </styled.NewItemContainer>
  );
};

export default React.memo(ChargingLocations);
