import { VehicleChargingBehavior } from '@hiven-energy/hiven-client';
import { BatteryEmptyIcon, BatteryHalfIcon, EditIcon, FlashIcon, PinIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { EditPanel } from 'src/components/EditPanel/EditPanel';
import { LabelValue } from 'src/components/LabelValue/LabelValue';
import ReadyTime from 'src/containers/ready-time/Dashboard/ReadyTime';
import { Preferences as PreferencesType } from 'src/screens/vehicle/types';

import * as styled from './styles';

interface Props {
  preferences: PreferencesType;
  onEditPress: VoidFunction;
}

const Preferences: FC<Props> = ({ preferences, onEditPress }) => {
  const intl = useIntl();

  const formatPercents = (value: number) => intl.formatNumber(value / 100, { style: 'percent' });

  const { chargeLevels, chargingBehavior, readyTime, chargingLocations } = preferences;
  const deviceLocation = chargingLocations.map(location => location.payload.name).join(', ');

  return (
    <EditPanel
      titleLabel="VehicleDashboard.preferences.setUp.label"
      editButton={{
        title: intl.formatMessage({ id: 'common.edit' }),
        onPress: onEditPress,
        rightIcon: EditIcon,
      }}
    >
      <styled.Content>
        <LabelValue
          icon={PinIcon}
          label={intl.formatMessage({ id: 'VehiclePreferences.deviceLocation.label' })}
          value={deviceLocation}
        />
        <ReadyTime readyTime={readyTime} />
        <LabelValue
          icon={BatteryEmptyIcon}
          label={intl.formatMessage({ id: 'VehiclePreferences.chargeLevels.minimum.label' })}
          value={formatPercents(chargeLevels.minimum)}
        />
        <LabelValue
          icon={BatteryHalfIcon}
          label={intl.formatMessage({ id: 'VehiclePreferences.chargeLevels.maximum.label' })}
          value={formatPercents(chargeLevels.maximum)}
        />
        <LabelValue
          icon={FlashIcon}
          label={intl.formatMessage({ id: 'VehiclePreferences.chargingBehavior.label' })}
          value={
            chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_AT_LOCATION
              ? intl.formatMessage({ id: 'vehicle.chargingBehavior.SMART_CHARGE_AT_LOCATION' })
              : chargingBehavior === VehicleChargingBehavior.SMART_CHARGE_ANYWHERE
              ? intl.formatMessage({ id: 'vehicle.chargingBehavior.SMART_CHARGE_ANYWHERE' })
              : intl.formatMessage({ id: 'vehicle.chargingBehavior.SMART_CHARGE_OFF' })
          }
        />
      </styled.Content>
    </EditPanel>
  );
};

export default React.memo(Preferences);
