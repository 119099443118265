import { Slider, SliderProps, Typography } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useDebounce } from 'use-debounce';

import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';

import { ChargeLevelUnit } from '../../types';

import * as styled from './styles';

interface Props {
  label: string;
  description: string;
  value: number;
  unit: ChargeLevelUnit;
  limit: number;
  hint?: string;
  sliderColor?: string;
  onChange: (value: number) => void;
}

const DEBOUNCE = 1000;

const Card: FC<Props> = ({ label, description, value, unit, limit, hint, sliderColor, onChange }) => {
  useAnalyticsTimeEvent(MixpanelEvents.USER_INTERACTED, [value]);
  const { trackSliderChange } = useAnalytics();
  const [trackDebounced] = useDebounce(trackSliderChange, DEBOUNCE);

  const handleChange: SliderProps['onChange'] = _value => {
    onChange(_value);
    trackDebounced(label, `${_value}`);
  };
  return (
    <styled.Container>
      <Typography variant="h2">{label}</Typography>
      <styled.Content>
        <Slider value={value} maxValue={limit} unit={unit} onChange={handleChange} color={sliderColor} />
        {hint ? <styled.Hint>{hint}</styled.Hint> : null}
        <styled.Description>{description}</styled.Description>
      </styled.Content>
    </styled.Container>
  );
};

export default React.memo(Card);
