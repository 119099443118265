import { createStackNavigator } from '@react-navigation/stack';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppTheme } from 'src/app-theme';
import Account from 'src/screens/Account/Account';
import ChangePassword from 'src/screens/authentication/ChangePassword/ChangePassword';
import ConfirmSignUp from 'src/screens/authentication/ConfirmSignUp/ConfirmSignUp';
import ConfirmSuccess from 'src/screens/authentication/ConfirmSuccess/ConfirmSuccess';
import ResetPassword from 'src/screens/authentication/ResetPassword/ResetPassword';
import SignIn from 'src/screens/authentication/SignIn/SignIn';
import SignUp from 'src/screens/authentication/SignUp/SignUp';
import BrandGroups from 'src/screens/brands/BrandGroups/BrandGroups';
import ChargerBrands from 'src/screens/brands/ChargerBrands/ChargerBrands';
import VehicleBrands from 'src/screens/brands/VehicleBrands/VehicleBrands';
import ChangeLanguage from 'src/screens/ChangeLanguage/ChangeLanguage';
import ChargerAnalytics from 'src/screens/charger/Analytics/Analytics';
import ChargerDashboard from 'src/screens/charger/Dashboard/Dashboard';
import ChargerOverview from 'src/screens/charger/Overview/Overview';
import ChargerPreferences from 'src/screens/charger/Preferences/Preferences';
import ChargingLocation from 'src/screens/ChargingLocation/ChargingLocation';
import ChargingLocationHeader from 'src/screens/ChargingLocation/ChargingLocationHeader';
import ChargerConnect from 'src/screens/connect/ChargerConnect/ChargerConnect';
import SmartCarConnect from 'src/screens/connect/SmartCarConnect/SmartCarConnect';
import WallboxConnect from 'src/screens/connect/WallboxConnect/WallboxConnect';
import Consents from 'src/screens/Consents/Consents';
import DevicePairing from 'src/screens/DevicePairing/DevicePairing';
import GetStarted from 'src/screens/GetStarted/GetStarted';
import Initial from 'src/screens/Initial/Initial';
import MyChargingLocations from 'src/screens/MyChargingLocations';
import MyVehiclesAndChargers from 'src/screens/MyVehiclesAndChargers';
import Onboarding from 'src/screens/Onboarding/Onboarding';
import SetChargingLocation from 'src/screens/SetChargingLocation/SetChargingLocation';
import SetTariffAndMeter from 'src/screens/SetTariffAndMeter/SetTariffAndMeter';
import DashboardSettings from 'src/screens/Settings/Settings';
import SmartCarDocs from 'src/screens/SmartCarDocs/SmartCarDocs';
import VehicleAnalytics from 'src/screens/vehicle/Analytics/Analytics';
import VehicleDashboard from 'src/screens/vehicle/Dashboard/Dashboard';
import VehicleOverview from 'src/screens/vehicle/Overview/Overview';
import VehiclePreferences from 'src/screens/vehicle/Preferences/Preferences';
import WallboxLogin from 'src/screens/WallboxLogin/WallboxLogin';
import { useSession } from 'src/store/session';
import * as theme from 'src/theme';

import Header from './components/Header/Header';
import { RouteHeaderAction, RouteId, RoutesParamList } from './types';

const StackNavigator = createStackNavigator<RoutesParamList>();

const Navigator: FC = () => {
  const session = useSession();
  const intl = useIntl();
  const appTheme = useAppTheme();
  return (
    <StackNavigator.Navigator
      screenOptions={{
        cardStyle: {
          backgroundColor: theme.colors.white,
        },
      }}
    >
      {session.loggedIn ? (
        <>
          <StackNavigator.Screen
            name={RouteId.Initial}
            options={() => ({
              header: () => null,
              cardStyle: {
                backgroundColor: appTheme.main.color,
              },
            })}
            component={Initial}
          />
          <StackNavigator.Screen
            name={RouteId.MyVehiclesAndChargers}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.MyVehiclesAndChargers" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={MyVehiclesAndChargers}
          />
          <StackNavigator.Screen
            name={RouteId.MyChargingLocations}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.MyChargingLocations" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={MyChargingLocations}
          />
          <StackNavigator.Screen
            name={RouteId.ChargingLocation}
            options={({ route }) => ({
              header: () => <ChargingLocationHeader route={route} />,
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={ChargingLocation}
          />
          <StackNavigator.Screen
            name={RouteId.SetChargingLocation}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={
                    <FormattedMessage
                      id={
                        route.params.locationId
                          ? 'navigation.screen.SetChargingLocation'
                          : 'navigation.screen.AddChargingLocation'
                      }
                    />
                  }
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={SetChargingLocation}
          />
          <StackNavigator.Screen
            name={RouteId.SetTariffAndMeter}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.SetTariffAndMeter" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={SetTariffAndMeter}
          />
          <StackNavigator.Screen
            name={RouteId.BrandGroups}
            options={({ route }) => {
              const { disableGoBack } = route.params || {};
              return {
                header: () => (
                  <Header
                    title={<FormattedMessage id="navigation.screen.DeviceType" />}
                    action={disableGoBack ? undefined : RouteHeaderAction.GO_BACK}
                    route={route}
                  />
                ),
              };
            }}
            component={BrandGroups}
          />
          <StackNavigator.Screen
            name={RouteId.VehicleBrands}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.VehicleManufacturersList" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
            })}
            component={VehicleBrands}
          />
          <StackNavigator.Screen
            name={RouteId.ChargerBrands}
            options={({ route }) => {
              const { disableGoBack } = route.params || {};
              return {
                header: () => (
                  <Header
                    title={<FormattedMessage id="navigation.screen.ChargerManufacturersList" />}
                    action={disableGoBack ? undefined : RouteHeaderAction.GO_BACK}
                    route={route}
                  />
                ),
              };
            }}
            component={ChargerBrands}
          />

          <StackNavigator.Screen
            name={RouteId.VehicleDashboard}
            options={{
              headerShown: false,
              animationEnabled: false,
            }}
            component={VehicleDashboard}
          />
          <StackNavigator.Screen
            name={RouteId.ChargerDashboard}
            options={{
              headerShown: false,
              animationEnabled: false,
            }}
            component={ChargerDashboard}
          />
          <StackNavigator.Screen
            name={RouteId.VehicleAnalytics}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.Analytics" />} route={route} type="large" />
              ),
              animationEnabled: false,
            })}
            component={VehicleAnalytics}
          />
          <StackNavigator.Screen
            name={RouteId.ChargerAnalytics}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.Analytics" />} route={route} type="large" />
              ),
              animationEnabled: false,
            })}
            component={ChargerAnalytics}
          />
          <StackNavigator.Screen
            name={RouteId.Settings}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.Settings" />} route={route} type="large" />
              ),
              animationEnabled: false,
            })}
            component={DashboardSettings}
          />
          <StackNavigator.Screen
            name={RouteId.SmartCarConnect}
            options={() => ({
              headerShown: false,
              cardStyle: {
                backgroundColor: appTheme.main.color,
              },
            })}
            component={SmartCarConnect}
          />
          <StackNavigator.Screen
            name={RouteId.SmartCarDocs}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.SmartCarDocs" />}
                  route={route}
                  action={RouteHeaderAction.GO_BACK}
                />
              ),
            })}
            component={SmartCarDocs}
          />
          <StackNavigator.Screen
            name={RouteId.ChargerConnect}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.ChargerConnect" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
            })}
            component={ChargerConnect}
          />
          <StackNavigator.Screen
            name={RouteId.WallboxLogin}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.WallboxLogin" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
            })}
            component={WallboxLogin}
          />
          <StackNavigator.Screen
            name={RouteId.WallboxConnect}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.WallboxConnect" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
            })}
            component={WallboxConnect}
          />
          <StackNavigator.Screen
            name={RouteId.VehiclePreferences}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.VehiclePreferences' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.VehiclePreferences" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
            })}
            component={VehiclePreferences}
          />
          <StackNavigator.Screen
            name={RouteId.VehicleOverview}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.VehicleOverview" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={VehicleOverview}
          />
          <StackNavigator.Screen
            name={RouteId.ChargerPreferences}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.ChargerPreferences' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.ChargerPreferences" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={ChargerPreferences}
          />
          <StackNavigator.Screen
            name={RouteId.ChargerOverview}
            options={({ route }) => ({
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.ChargerOverview" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={ChargerOverview}
          />
          <StackNavigator.Screen
            name={RouteId.DevicePairing}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.DevicePairing' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.DevicePairing" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={DevicePairing}
          />
          <StackNavigator.Screen
            name={RouteId.ChangePassword}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.ChangePassword' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.ChangePassword" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={ChangePassword}
          />
          <StackNavigator.Screen
            name={RouteId.ChangeLanguage}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.ChangeLanguage' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.ChangeLanguage" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={ChangeLanguage}
          />
          <StackNavigator.Screen
            name={RouteId.Account}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.Account' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.Account" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={Account}
          />
          <StackNavigator.Screen
            name={RouteId.Consents}
            options={({ route }) => ({
              title: intl.formatMessage({ id: 'navigation.screen.Consents' }),
              header: () => (
                <Header
                  title={<FormattedMessage id="navigation.screen.Consents" />}
                  action={RouteHeaderAction.GO_BACK}
                  route={route}
                />
              ),
              cardStyle: {
                backgroundColor: theme.colors.white,
              },
            })}
            component={Consents}
          />
        </>
      ) : (
        <>
          <StackNavigator.Screen
            name={RouteId.GetStarted}
            options={() => ({
              headerShown: false,
              cardStyle: {
                backgroundColor: appTheme.main.color,
              },
            })}
            component={GetStarted}
          />
          <StackNavigator.Screen
            name={RouteId.Onboarding}
            options={() => ({
              headerShown: false,
            })}
            component={Onboarding}
          />
          <StackNavigator.Screen
            name={RouteId.SignIn}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.SignIn" />} route={route} type="large" />
              ),
            })}
            component={SignIn}
          />
          <StackNavigator.Screen
            name={RouteId.SignUp}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.SignUp" />} route={route} type="large" />
              ),
            })}
            component={SignUp}
          />
          <StackNavigator.Screen
            name={RouteId.ConfirmSignUp}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.ConfirmSignUp" />} route={route} type="large" />
              ),
            })}
            component={ConfirmSignUp}
          />
          <StackNavigator.Screen
            name={RouteId.ConfirmSuccess}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.ConfirmSuccess" />} route={route} type="large" />
              ),
            })}
            component={ConfirmSuccess}
          />
          <StackNavigator.Screen
            name={RouteId.ResetPassword}
            options={({ route }) => ({
              header: () => (
                <Header title={<FormattedMessage id="navigation.screen.ResetPassword" />} route={route} type="large" />
              ),
            })}
            component={ResetPassword}
          />
        </>
      )}
    </StackNavigator.Navigator>
  );
};

export default Navigator;
